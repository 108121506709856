<template>
	<div class="notice_popup">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기">
		</div>
		<div class="tit">기술인재평가 메인노출<template v-if="this.param.mainViewYn == 'N'"> 종료</template></div>
		<div class="member">
			해당 기술인재평가를<br/>
			<template v-if="this.param.mainViewYn == 'Y'">메인화면에 노출하시겠습니까?</template>
			<template v-if="this.param.mainViewYn == 'N'">메인화면에서 노출종료하시겠습니까?</template>
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="send()">예</div> 
		</div>
	</div>
</template>
<script>

export default {
	props: { param:Object },
	data() {
		return {
			list: this.param.list,
		};
	},
	beforeMount(){
		if(!Array.isArray(this.param.list) || this.param.list.length == 0){
			alert('평가정보를 확인 할 수 없습니다.');
			this.$emit('close');
		} else if(['Y','N'].indexOf(this.param.mainViewYn) < 0){
			alert('노출여부를 확인 할 수 없습니다.');
			this.$emit('close');
		}
	},
	methods: {
		send(){
			var mainViewYn = this.param.mainViewYn;
			var list = this.param.list;
			this.$.httpPost('/api/mem/adm/evl/setMainViewYn', { list, mainViewYn })
				.then(() => {
					//alert('배너 삭제를 완료하였습니다.');
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
		}
	},
};
</script>
